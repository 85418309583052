//
// components.scss
//

//component-wrapper
.component-wrapper {
	.title {
		font-size: 20px;
	}
	&.sticky-bar {
		li {
			margin-bottom: 6px;
			&:last-child {
				margin-bottom: 0px !important;
			}
			a {
				transition: all 0.5s ease;
				&:hover,
				&:focus {
					color: $primary !important;
				}
			}
		}
	}
}

//Buttons
@each $name, $value in $colors {
	.btn-#{$name} {
		background-color: #{$value} !important;
		border: 1px solid #{$value} !important;
		color: $white !important;
		box-shadow: 0 3px 5px 0 rgba($value, 0.3);
		&:hover,
		&:focus,
		&:active,
		&.active,
		&.focus {
			background-color: darken($value, 10%) !important;
			border-color: darken($value, 10%) !important;
			color: $white !important;
		}
	}

	.btn-soft-#{$name} {
		background-color: rgba($value, 0.2) !important;
		border: 1px solid rgba($value, 0.2) !important;
		color: #{$value} !important;
		box-shadow: 0 3px 5px 0 rgba($value, 0.3);
		&:hover,
		&:focus,
		&:active,
		&.active,
		&.focus {
			background-color: #{$value} !important;
			border-color: #{$value} !important;
			color: $white !important;
		}
	}

	.btn-outline-#{$name} {
		border: 1px solid #{$value};
		color: #{$value};
		background-color: transparent;
		&:hover,
		&:focus,
		&:active,
		&.active,
		&.focus,
		&:not(:disabled):not(.disabled):active {
			background-color: #{$value};
			border-color: #{$value};
			color: $white !important;
			box-shadow: 0 3px 5px 0 rgba($value, 0.3);
		}
	}
}
.btn {
	padding: 8px 20px;
	outline: none;
	text-decoration: none;
	font-size: 16px;
	letter-spacing: 0.5px;
	transition: all 0.3s;
	font-weight: 600;
	border-radius: 6px;
	&:focus {
		box-shadow: none !important;
	}
	&.btn-sm {
		padding: 7px 16px;
		font-size: 10px;
	}
	&.btn-lg {
		padding: 14px 30px;
		font-size: 16px;
	}
	&.searchbtn {
		padding: 6px 20px;
	}
	&.btn-pills {
		border-radius: 30px;
	}
	&.btn-light {
		color: $dark !important;
		border: 1px solid $gray-300 !important;
		&:hover,
		&:focus,
		&:active,
		&.active,
		&.focus {
			background-color: darken($light, 10%) !important;
			color: $dark !important;
		}
	}
	&.btn-soft-light {
		color: rgba($dark, 0.5) !important;
		border: 1px solid $gray-300 !important;
		&:hover,
		&:focus,
		&:active,
		&.active,
		&.focus {
			color: $dark !important;
		}
	}
	&.btn-outline-light {
		border: 1px solid $gray-300 !important;
		color: $dark !important;
		background-color: transparent;
		&:hover,
		&:focus,
		&:active,
		&.active,
		&.focus {
			background-color: $light !important;
		}
	}
	&.btn-icon {
		height: 36px;
		width: 36px;
		line-height: 34px;
		padding: 0;
		.icons {
			height: 16px;
			width: 16px;
			font-size: 16px;
		}
		&.btn-lg {
			height: 48px;
			width: 48px;
			line-height: 46px;
			.icons {
				height: 20px;
				width: 20px;
				font-size: 20px;
			}
		}
		&.btn-sm {
			height: 30px;
			width: 30px;
			line-height: 28px;
		}
	}
}

button:not(:disabled) {
	outline: none;
}

//Shadow
.shadow {
	box-shadow: $shadow !important;
}

.shadow-lg {
	box-shadow: $shadow-lg !important;
}

//Badges
@each $name, $value in $colors {
	.badge-#{$name} {
		background-color: #{$value} !important;
		color: $white !important;
	}

	.badge-outline-#{$name} {
		background-color: transparent !important;
		color: #{$value} !important;
		border: 1px solid #{$value} !important;
	}
}
.badge {
	padding: 5px 8px;
	border-radius: 3px;
	letter-spacing: 0.5px;
	&.badge-light {
		color: $dark !important;
		background-color: $light !important;
	}

	&.badge-outline-light {
		color: $dark !important;
		border: 1px solid darken($light, 20%) !important;
		background-color: transparent !important;
	}
	&.badge-pill {
		border-radius: 12px;
	}
	&:focus,
	&.focus {
		box-shadow: none !important;
	}
}

//Dropdowns
@each $name, $value in $colors {
	.dropdown-#{$name} {
		.dropdown-menu {
			.dropdown-item {
				&:hover,
				&.active,
				&:active,
				&.focus,
				&:focus {
					background-color: transparent;
					color: #{$value} !important;
				}
			}
		}
	}
}

.btn-group {
	.dropdown-toggle {
		&:after {
			content: "";
			position: relative;
			right: -4px;
			top: -2px;
			border: solid $white;
			border-radius: 0.5px;
			border-width: 0 2px 2px 0;
			padding: 3px;
			transform: rotate(45deg);
			margin-left: 0;
			vertical-align: 0;
		}
	}
	.dropdown-menu {
		margin-top: 10px;
		border: 0;
		border-radius: 5px;
		box-shadow: $shadow;
		&:before {
			content: "";
			position: absolute;
			top: 2px;
			left: 45px;
			box-sizing: border-box;
			border: 7px solid $gray-700;
			border-radius: 0.5px;
			border-color: transparent transparent $white $white;
			transform-origin: 0 0;
			transform: rotate(135deg);
			box-shadow: -2px 2px 2px -1px rgba($black, 0.15);
		}
	}
}

//Alert
@each $name, $value in $colors {
	.alert-#{$name} {
		background-color: rgba($value, 0.9);
		color: $white;
		border-color: $value;
		.alert-link {
			color: darken($value, 30%);
		}
	}
	.alert-outline-#{$name} {
		background-color: $white;
		color: $value;
		border-color: $value;
	}
}
.alert {
	padding: 8px 15px;
	border-radius: 6px;
	font-size: 14px;
	&.alert-light {
		background-color: $light;
		color: $dark;
		border-color: $gray-300;
	}
	&.alert-dismissible {
		padding-right: 30px;
		.close {
			top: -2px;
			right: -10px;
			font-size: 16px !important;
		}
	}
	&.alert-pills {
		border-radius: 30px;
		display: inline-block;
		.content {
			font-weight: 600;
		}
	}
}

//Breadcrumb
.page-next-level {
	.title {
		font-size: 28px;
		letter-spacing: 1px;
	}
	.page-next {
		position: relative;
		top: 110px;
		z-index: 99;
	}
}

.breadcrumb {
	letter-spacing: 0.5px;
	padding: 8px 24px;
	display: block;
	.breadcrumb-item {
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;
		padding-left: 0;
		display: inline-block;
		a {
			color: $dark;
			&:hover {
				color: $primary;
			}
		}
		&.active {
			color: $primary;
		}
		&:before {
			content: "";
		}
		&:after {
			content: "\F0142" !important;
			font-size: 14px;
			color: $dark;
			font-family: "Material Design Icons";
			padding-left: 5px;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}

//Pagination
.pagination {
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 30px;
			border-bottom-left-radius: 30px;
		}
		&:last-child .page-link {
			border-top-right-radius: 30px;
			border-bottom-right-radius: 30px;
		}
		.page-link {
			color: $dark;
			border: 1px solid $gray-300;
			padding: 10px 15px;
			font-size: 14px;
			&:focus {
				box-shadow: none;
			}
			&:hover {
				color: $white;
				background: rgba($primary, 0.9);
				border-color: rgba($primary, 0.9);
			}
		}
		&.active {
			.page-link {
				color: $white;
				background: $primary !important;
				border-color: $primary;
				cursor: not-allowed;
			}
		}
	}
}

//Avatar
.avatar {
	&.avatar-ex-sm {
		max-height: 25px;
	}
	&.avatar-md-sm {
		height: 45px;
		width: 45px;
	}
	&.avatar-small {
		height: 65px;
		width: 65px;
	}
	&.avatar-md-md {
		height: 80px;
		width: 80px;
	}
	&.avatar-medium {
		height: 110px;
		width: 110px;
	}
	&.avatar-large {
		height: 140px;
		width: 140px;
	}
	&.avatar-ex-large {
		height: 180px;
		width: 180px;
	}
}

//FAQ
.faq-container {
	.question {
		font-size: 20px;
	}
}

.faq-content {
	.card {
		.faq {
			.card-header {
				padding-right: 40px !important;
			}
		}
		a.faq[data-toggle="collapse"] {
			background-color: $white;
			color: $primary;
			transition: all 0.5s;
			&:before {
				content: "\F0143";
				display: block;
				font-family: "Material Design Icons";
				font-size: 18px;
				color: $dark;
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
				transition: all 0.5s ease;
			}
			&.collapsed {
				background-color: $white;
				color: $dark !important;
				transition: all 0.5s;
				&:before {
					top: 15px;
					transform: rotate(180deg);
					color: $dark !important;
				}
			}
		}
		.title {
			font-size: 16px;
		}
	}
}

//Nav
.nav-pills {
	margin-bottom: 15px;
	background: darken($light, 1%);
	.nav-link {
		color: $gray-700 !important;
		padding: 5px;
		transition: all 0.5s ease;
		&.active {
			background: $primary;
			color: $white !important;
			.tab-para {
				color: $light !important;
			}
		}
	}
	a {
		.title {
			font-size: 17px;
			font-weight: 700;
		}
	}
}

//Processbar
.progress-box {
	.title {
		font-size: 15px;
	}
	.progress {
		height: 8px;
		overflow: visible;
		.progress-bar {
			border-radius: 6px;
			animation: animate-positive 3s;
			overflow: visible !important;
		}
		.progress-value {
			position: absolute;
			top: -25px;
			right: -15px;
			font-size: 13px;
		}
	}
	@keyframes animate-positive {
		0% {
			width: 0;
		}
	}
}

//blockquote
.blockquote {
	border-left: 3px solid $gray-300;
	border-radius: 6px;
	font-size: 16px;
}

//Form
.form-group {
	margin-bottom: 20px;
	label {
		font-size: 14px;
		font-weight: 700;
	}
	.form-control {
		box-shadow: none;
		background-color: $white;
		border: 1px solid $gray-300;
		color: $dark;
		height: 45px;
		font-size: 15px;
		border-radius: 0px;
		transition: all 0.5s ease;
		&:focus,
		&.active {
			border-color: #2064b4;
		}
	}
	textarea {
		height: 120px !important;
		&.form-control {
			line-height: 25px;
		}
	}
}
.form-control:disabled,
.form-control[readonly] {
	background-color: transparent;
	opacity: 1;
}
//Checkbox / Radio button
.custom-control-input:checked ~ .custom-control-label {
	&:before {
		color: $white;
		border-color: $primary;
		background-color: $primary;
	}
}
.custom-control-input:focus ~ .custom-control-label::before,
.form-control:focus {
	box-shadow: none;
	border-color: $primary;
}

.custom-control-label {
	cursor: pointer;
	&:before,
	&:after {
		top: 2px;
	}
}
.custom-switch .custom-control-label {
	&:after {
		top: 4px;
	}
}

//Subscribe form
.subcribe-form {
	input {
		padding: 14px 20px;
		width: 100%;
		color: $dark !important;
		border: none;
		outline: none !important;
		padding-right: 160px;
		padding-left: 30px;
		background-color: rgba($white, 0.8);
	}
	button {
		position: absolute;
		top: 6px;
		right: 6px;
		outline: none !important;
	}
	form {
		position: relative;
		max-width: 600px;
		margin: 0px auto;
	}
}

//Table
.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	.table {
		td,
		th {
			vertical-align: middle;
		}
	}
	.table-center {
		th {
			vertical-align: middle !important;
			cursor: pointer;
		}
		tbody {
			tr {
				&:hover {
					color: $dark;
					background-color: $gray-100;
				}
			}
		}
		&.invoice-tb {
			th,
			td {
				text-align: end;
			}
		}
	}
}
//modal
.modal-open {
	padding-right: 0 !important;
}

// Loader
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: linear-gradient(45deg, $white, $white);
	z-index: 9999999;
	#status {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		.spinner {
			width: 40px;
			height: 40px;
			position: relative;
			margin: 100px auto;
			.double-bounce1,
			.double-bounce2 {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: $primary;
				opacity: 0.6;
				position: absolute;
				top: 0;
				left: 0;
				animation: sk-bounce 2s infinite ease-in-out;
			}
			.double-bounce2 {
				animation-delay: -1s;
			}
		}
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
	}
}

//Social icons
.social-icon {
	li {
		a {
			color: $dark;
			border: 1px solid $dark;
			display: inline-block;
			height: 32px;
			width: 32px;
			line-height: 28px;
			text-align: center;
			transition: all 0.4s ease;
			overflow: hidden;
			position: relative;
			.fea-social {
				stroke-width: 2;
			}
			&:hover {
				background-color: $primary;
				border-color: $primary !important;
				color: $white !important;
				.fea-social {
					fill: $primary;
				}
			}
		}
	}
	&.social {
		li {
			a {
				color: $gray-500;
				border-color: $gray-500;
			}
		}
	}
}
//back to top
.back-to-top {
	z-index: 99;
	position: fixed;
	bottom: 30px;
	right: 30px;
	display: none;
	transition: all 0.5s ease;
	.icons {
		transition: all 0.5s ease;
	}
	&:hover {
		transform: rotate(45deg);
		.icons {
			transform: rotate(-45deg);
		}
	}
}

//back-to-home
.back-to-home {
	position: absolute;
	top: 4%;
	right: 2%;
	z-index: 1;
}

//Sticky Sidebar
.sticky-bar {
	position: sticky;
	top: 80px;
}

//Feather Icon
.fea {
	stroke-width: 1.8;
	&.icon-sm {
		height: 16px;
		width: 16px;
	}
	&.icon-ex-md {
		height: 20px;
		width: 20px;
	}
	&.icon-m-md {
		height: 28px;
		width: 28px;
	}
	&.icon-md {
		height: 35px;
		width: 35px;
	}
	&.icon-lg {
		height: 42px;
		width: 42px;
	}
	&.icon-ex-lg {
		height: 52px;
		width: 52px;
	}
}

//Display
.display-1 {
	font-size: 80px !important;
}
.display-2 {
	font-size: 72px !important;
}
.display-3 {
	font-size: 64px !important;
}
.display-4 {
	font-size: 56px !important;
}

[class^="uil-"],
[class*=" uil-"] {
	&:before {
		margin: 0;
	}
}

@media (max-width: 768px) {
	.page-next-level {
		.page-next {
			top: 70px;
		}
	}
	.display-1 {
		font-size: 56px !important;
	}
	.display-2 {
		font-size: 50px !important;
	}
	.display-3 {
		font-size: 42px !important;
	}
	.display-4 {
		font-size: 36px !important;
	}
}
